
import Vue from 'vue';

export default Vue.extend({
	name: 'Snackbar',

	data() {
		return {
			show: false,

			position: '',
			message: '',
			color: undefined as string | undefined,
			time: 0,
			type: '',
			errorDetails: ''
		};
	},

	computed: {
		colorPicked(): string {
			if (this.color) {
				return this.color;
			}
			switch (this.type) {
				case 'success':
					return 'orange123';
				case 'error':
					return 'red lighten-1';
				default:
					return 'orange123';
			}
		},

		snackTime(): number {
			return this.time > 0 ? this.time : 3500;
		}
	},

	created() {
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'showSnackbar') {
				const snackbar = JSON.parse(JSON.stringify(state.UTILS.snackbar));
				this.message = snackbar.content;
				this.type = snackbar.type;
				this.position = snackbar.position;
				this.time = snackbar.time;
				this.color = snackbar.color ? snackbar.color : undefined;
				this.show = true;
				this.errorDetails = snackbar.errorDetails;
			}
		});
	}
});
