import { Customer } from '@/types/customer/customer';

const cleanAuth = () => {
	console.log('Auth issue (401) -> Clearing auth token/cookie...');
	if (!document || !localStorage) {
		return;
	}
	// Remove token form localstorage
	localStorage.removeItem('JStoken');
};

export default function ({ $axios, store, redirect }) {
	$axios.onError((error) => {
		const code = parseInt(error.response && error.response.status);

		if (code !== 401) {
			return;
		}
		store.commit('setPageLoader', true);
		// Auth error - Cookie isn't set or invalid - Logout user and clean everything)
		store.dispatch('CLEAR_BOOKING_STORED');
		store.commit('setLoggedInState', false);
		store.commit('storeUserData', new Customer());

		cleanAuth();

		redirect('/');
	});
}
