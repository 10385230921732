import { AModel } from '@/types/class_helper';

export enum InvoiceState {
	Open = 'OPEN',
	Paid = 'PAID',
	Canceled = 'CANCELED',
	Deprecated = 'DEPR'
}

export enum InvoiceType {
	Invoice = 'INVOICE',
	Cancellation = 'CANCELLATION',
	Expense = 'EXPENSE',
	ExpenseCancellation = 'EXPENSE_CANCELLATION',
	Deposit = 'DEPOSIT',
	DepositRefund = 'DEPOSIT_REDUND',
	Offert = 'OFFERT'
}

export enum InvoiceSubtype {
	Booking = 'BOOKING',
	Upgrade = 'UPGRADE',
	Special = 'SPECIAL',
	Abo = 'ABO',
	Deposit = 'DEPOSIT',
	Free = 'FREE'
}

export interface InvoiceDocument {
	type: InvoiceType
	name: string
	link: string
}

export class Invoice extends AModel {
	id: string;
	bu: string;
	number: string;
	customer: string;
	date_create: string;
	total_gross: number;
	total_net: number;
	currency: string;
	state: InvoiceState;
	type: InvoiceType;
	subtype: InvoiceSubtype;
	has_dispute: boolean;
	has_handover: boolean;
	has_correction: boolean;
	documents: InvoiceDocument[] | null

	constructor(opt?: Partial<Invoice>) {
		super();

		// Default values (all props)
		this.id = '';
		this.bu = '';
		this.number = '';
		this.customer = '';
		this.date_create = '';
		this.total_gross = 0;
		this.total_net = 0;
		this.currency = '';
		this.state = InvoiceState.Open;
		this.type = InvoiceType.Invoice;
		this.subtype = InvoiceSubtype.Booking;
		this.has_dispute = false;
		this.has_handover = false;
		this.has_correction = false;
		this.documents = null

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Invoice, Partial<Invoice>>(this, sanitized);
		}
	}

	copy(): Invoice {
		const copy = new Invoice(this.toObject());
		return copy;
	}
}
