import { AModel } from '@/types/class_helper';

export class Batch extends AModel {
	id: string;
	name: string;
	size: number;
	date_start: string;
	date_end: string;

	constructor(opt?: Partial<Batch>) {
		super();

		// Default values (all props)
		this.id = '';
		this.name = '';
		this.size = 0;
		this.date_start = '';
		this.date_end = '';

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Batch, Partial<Batch>>(this, sanitized);
		}
	}

	copy(): Batch {
		const copy = new Batch(this.toObject());
		return copy;
	}
}

export default Batch;
