import { Plugin } from '@nuxt/types';
import { ISnackbar } from '~/types/misc_data';

declare module 'vue/types/vue' {
	interface Vue {
		$snackbar: {
			showMessage: (payload: any) => void;
		}
	}
}

declare module '@nuxt/types' {
	// nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
	interface NuxtAppOptions {
		$snackbar: {
			showMessage: (payload: any) => void;
		}
	}
	// nuxtContext.$myInjectedFunction
	interface Context {
		$snackbar: {
			showMessage: (payload: any) => void;
		}
	}
}

const snackbarPlugin: Plugin = ({ store }, inject) => {
	inject('snackbar', {
		showMessage(payload: ISnackbar) {
			store.commit('showSnackbar', payload);
		}
	});
};

export default snackbarPlugin;
