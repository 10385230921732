export class SerializeStoreObject {
	// Inherited by 'model' classes with constructor (gets rid of the non POJOS stringify errors)
	[key: string]: any; // gets rid of typescript linter errors when trying to access object properties through eg. object[i]

	toJSON(): any {
		// to prevent the stringify POJOs warnings on server
		const res = Object.getOwnPropertyNames(this).reduce((a, b) => {
			if (b !== '__ob__') {
				(a as any)[b] = (this as any)[b];
			}
			return a;
		}, {});
		return res;
	}
}

interface IDictionary<TValue> {
	[id: string]: TValue;
}

export abstract class AModel {
	[index: string]: any;

	toObject(): Record<string, unknown> {
		const obj: { [index: string]: any } = {};
		const keys = Object.keys(this);
		for (const k in keys) {
			const prop = this[keys[k]];
			if (prop instanceof AModel) {
				obj[keys[k]] = prop.toObject();
			} else if (prop instanceof Array) {
				obj[keys[k]] = prop.map((e) => e);
			} else if (prop instanceof Object) {
				const tmp: { [index: string]: any } = {};
				for (const i in prop) {
					tmp[i] = prop[i];
				}
				obj[keys[k]] = tmp;
			} else {
				obj[keys[k]] = prop;
			}
		}
		return obj;
	}

	toDTO(): Record<string, unknown> {
		return this.toObject();
	}

	toJSON(): any {
		return this.toDTO();
	}

	public static copyList<Type extends AModel>(input: IDictionary<Type>): Type[] {
		const copy = [];
		for (const id in input) {
			copy.push(input[id].copy());
		}

		return copy;
	}
}
