import { AModel } from '@/types/class_helper';

export class Site extends AModel {
	id: string;
	name: string;
	address: string;
	directions: string;
	site_id?: string;
	lat: number;
	lng: number;

	constructor(opt?: Partial<Site>) {
		super();

		// Default values (all props)
		this.id = '';
		this.name = '';
		this.address = '';
		this.directions = '';
		this.lat = 0;
		this.lng = 0;
		this.site_id = undefined;

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Site, Partial<Site>>(this, sanitized);
		}
	}

	copy(): Site {
		const copy = new Site(this.toObject());
		return copy;
	}
}

export default Site;
