export default async function (context: any): Promise<void> {
	// get current TLD and request server for press list data
	// console.log('current domain: ', context.$getDomain())
	const domain = context.$getDomain();
	const empty = context.store.state.MISC_DATA.press_list.length < 1;
	const domainChanged = context.store.state.MISC_DATA.current_domain !== domain;

	if (empty || domainChanged) {
		context.$logger.console({ message: 'fetchLPData article list' });
		await context.store.dispatch('GET_PRESS_LIST', domain);
	}
}
