import { AxiosError, AxiosResponse } from 'axios';
import { getModule, Module, VuexAction, VuexModule, VuexMutation } from 'nuxt-property-decorator';
import { router } from '@/router';
import { store } from '@/store';
import { ApiCustomerReview, ISnackbar } from '~/types/misc_data';

import { $axios } from '@/utils/api';
import { ErrorResponse } from '@/types/api_helper';

@Module({
	name: 'UTILS',
	store, // this basically injects the module in the store dynamically thanks to next line
	dynamic: true,
	stateFactory: true // apparently necessary/better for Nuxt
})
class UTILS extends VuexModule {
	snackbar = new ISnackbar();

	loaded_translation = {} as any;

	document_unfocus_title = '';

	dialog_buffer = [] as string[];

	page_loading = false;
	redirect_loading = false;
	nuxt_img_mobile_sizes = 'xs:92vw sm:92vw ';

	nonce = '';

	// ------------------------------------------------
	// ------------- Mutations ------------------------
	// ------------------------------------------------

	@VuexMutation
	showSnackbar(payload: ISnackbar) {
		this.snackbar.content = payload.content;
		this.snackbar.type = payload.type;
		this.snackbar.color = payload.color;
		if (payload.time) this.snackbar.time = payload.time;
		if (payload.position) this.snackbar.position = payload.position;
		if (payload.errorDetails) this.snackbar.errorDetails = payload.errorDetails;
	}

	@VuexMutation
	storeDocumentUnfocusTitle(title: string) {
		this.document_unfocus_title = title;
	}

	@VuexMutation
	storeTranslation(trans: any) {
		this.loaded_translation = trans;
	}

	@VuexMutation
	setPageLoader(state: boolean) {
		this.page_loading = state;
	}

	@VuexMutation
	setRedirectLoader(state: boolean) {
		this.redirect_loading = state;
	}

	@VuexMutation
	removeBuffedDialog(name: string) {
		if (this.dialog_buffer.includes(name)) {
			for (const i in this.dialog_buffer) {
				if (this.dialog_buffer[i] === name) {
					(this.dialog_buffer as any).splice(i, 1);
				}
			}
		}
	}

	@VuexMutation
	resetDialogBuffer() {
		this.dialog_buffer = [];
	}

	@VuexMutation
	openDialog(name: string) {
		if (!this.dialog_buffer.includes(name)) {
			// Dialog name caching
			this.dialog_buffer.push(name);
		}

		router.push({ query: { dialog: name } }); // Add a query of ?dialog=name
	}

	@VuexMutation
	closeDialog(name: string) {
		const current_dialog = router.currentRoute.query.dialog;
		if (this.dialog_buffer.length > 1 && name !== this.dialog_buffer[0]) {
			// To push back to parent dialog
			router.push({ query: { dialog: this.dialog_buffer[0] } }); // this only applies to 1 nested dialog (we should not have more anyway)
		} else if (current_dialog) {
			this.dialog_buffer = []; // clear buffer
			router.push({ path: router.currentRoute.path }); // Gets rid of all queries
		}
	}

	// ------------------------------------------------
	// ------------- Actions --------------------------
	// ------------------------------------------------

	@VuexAction
	GET_STATIC_GMAP_STYLE(styles: any): string {
		// Create string for google static map url based on our mapStyle object.
		let i = '';
		let n = styles;
		for (let t in n) {
			void 0 === n[t].featureType && (n[t].featureType = 'all');
			let e = 'style=feature:' + n[t].featureType;
			void 0 !== n[t].elementType && (e += '|element:' + n[t].elementType);
			for (let o in n[t].stylers) {
				let i = n[t].stylers[o],
					a = Object.keys(i)[0];
				void 0 !== a && (e += '|' + a + ':' + i[a].toString().replace('#', '0x'));
			}
			i += '&' + encodeURIComponent(e);
		}
		return i;
	}

	@VuexAction({ rawError: true })
	async GET_TRANSLATION(params: { lang: string; bu: string }): Promise<string | boolean> {
		const url = `https://api.123-transporter.at/v2/basedata/${params.bu}/translation?lang=${params.lang}`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				return res.data;
			})
			.catch((_err: AxiosError) => {
				console.log(_err);
				return false;
			});
	}

	@VuexAction({ rawError: true })
	async GET_CUST_REVIEWS(bu: string): Promise<ErrorResponse|ApiCustomerReview[]> {
		const url = `https://api.123-transporter.at/v2/basedata/${bu}/reviews`;

		return await $axios
			.get(url)
			.then((res: AxiosResponse) => {
				const reviews = [];
				for (const rev of res.data) {
					reviews.push(new ApiCustomerReview(rev.rating, rev.user, rev.title, rev.text, rev.timestamp));
				}
				return reviews;
			})
			.catch((err: AxiosError) => {
				return ErrorResponse.fromAxiosError(err);
			});
	}

	@VuexAction({ rawError: true })
	async SEND_CONTACT(payload: any): Promise<boolean> {
		const url = 'v2/contact-form';

		try {
			await $axios.post(url, payload).then((res) => res.data);
			return true;
		} catch (err: any) {
			console.error('SEND_CONTACT failed with: ', err);
		}
		return false;
	}
}

export default getModule(UTILS);
