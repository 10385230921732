import _Vue from 'vue';
import { Plugin } from '@nuxt/types'

import ConfirmDialog from  "./components/ConfirmDialog.vue"
import AlertDialog from  "./components/AlertDialog.vue"
import PromptDialog from  "./components/PromptDialog.vue"


declare module 'vue/types/vue' {
   interface Vue {
      $dialogs: {
         confirm: (message: string, title?: string, opt?: { color?:string, right_btn_text?: string, left_btn_text?: string }) => Promise<boolean>,
         alert: (message: string, title?: string, opt?: { color?:string }) => Promise<boolean>,
         prompt: (
            message: string, 
            title?: string,
            opt? : {
               type?: string,
               defaultValue?: string,
               required?: boolean
               label?: string,
               color?:string,
               okText?:string
            }
         ) => Promise<string | boolean |File | File[] | undefined>,
      }
   }
}

declare module '@nuxt/types' {
   // nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
   interface NuxtAppOptions {
      $dialogs: {
         confirm: (message: string, title?: string, opt?: { color?:string, right_btn_text?: string, left_btn_text?: string }) => Promise<boolean>,
         alert: (message: string, title?: string, opt?: { color?:string }) => Promise<boolean>,
         prompt: (
            message: string, 
            title?: string,
            opt? : {
               type?: string,
               defaultValue?: string,
               required?: boolean
               label?: string,
               color?:string,
               okText?:string
            }
         ) => Promise<string | boolean |File | File[] | undefined>,
      }
   }
   // nuxtContext.$myInjectedFunction
   interface Context {
      $dialogs: {
         confirm: (message: string, title?: string, opt?: { color?:string, right_btn_text?: string, left_btn_text?: string }) => Promise<boolean>,
         alert: (message: string, title?: string, opt?: { color?:string }) => Promise<boolean>,
         prompt: (
            message: string, 
            title?: string,
            opt? : {
               type?: string,
               defaultValue?: string,
               required?: boolean
               label?: string,
               color?:string,
               okText?:string
            }
         ) => Promise<string | boolean |File | File[] | undefined>,
      }
   }
}


const dialogsPlugin : Plugin = ({app}, inject) : void => {

   let i18n = app.i18n
   let vuetify = app.vuetify

   if (!vuetify) {
      throw new Error('Please provide vuetify. (dialogs plugin)');
   }
   if (!i18n) {
      throw new Error('Please provide i18n. (dialogs plugin)');
   }

      inject('dialogs', {
         confirm: async function (message: string, title?: string, opt?: {color?:string, right_btn_text?: string, left_btn_text?: string}) : Promise<boolean> {
            return new Promise((res) => {
               
               const instance = new ConfirmDialog({
                  vuetify,
                  i18n,
                  propsData: {
                     title: title, 
                     message: message,
                     color: opt?.color,
                     rightBtnText: opt?.right_btn_text,
                     leftBtnText: opt?.left_btn_text,
                  }
               })
               
               instance.$mount()
               instance.$on('click', res)
            }) as Promise<boolean>
         },
         alert: async function (message: string, title?: string, opt?: {color?:string}) : Promise<boolean> {
            return new Promise((res) => {

               const instance = new AlertDialog({
                  vuetify,
                  i18n,
                  propsData: {
                     title, message,
                     color: opt?.color
                  }
               })
               
               instance.$mount();
               instance.$on('click', res)
            }) as Promise<boolean>
         },
         prompt: async function (message: string, title?: string, opt?: {type?: string, defaultValue?: string, required?: boolean, label?: string, color?:string,okText?:string}) : Promise<string | boolean | File | File[]> {
            return new Promise((res) => {

               const instance = new PromptDialog({
                  vuetify,
                  i18n,
                  propsData: {
                     title,
                     message,
                     type: opt?.type,
                     default: opt?.defaultValue,
                     required: opt?.required,
                     label: opt?.label,
                     color: opt?.color,
                     okText: opt?.okText
                  }
               })
               
               instance.$mount()
               instance.$on('click', res)
            }) as Promise<boolean>
         }
   })
}

export default dialogsPlugin;

