import Vue from 'vue';
import Router from 'vue-router';
import goTo from 'vuetify/lib/services/goto';
import { routesNames, getBasePath, getRouteAliases, getRoutePath, getAllPaths, handlePath } from '~/router/route_helper';
import { Routes } from '@/router/routes';
import { $axios } from '@/utils/api';
import { extractDomain } from '@/plugins/domainDetection';
import { requiresAuthGuard, checkoutGuard, customerIncompleteGuard } from '@/router/guards';

// VUEX
import USER from '@/store/modules/UserModule';
import UTILS from '@/store/modules/UtilityModule';
import MISC_DATA from '@/store/modules/MiscDataModule';

Vue.use(Router);

// This totally gets rid of the error (Redirected from *original push url* to *redirected url* via a navigation guard)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject);
	}
	return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
	mode: 'history',
	routes: Routes,
	scrollBehavior: (to, from, savedPosition) => {
		const scrollTo = 0;
		const options = {};

		if (to.name === routesNames.home && !to.hash) {
			return;
		}

		if (to.hash === '#lp' && from.hash !== '#lp') {
			// for landing page only
			options.offset = -850;
		}

		if (to.query.dialog || (from.query.dialog && from.name === to.name)) {
			// prevent when a dialog is opened and closed
			return;
		}

		// If there's a saved position, just return it (like when using the browser's back button)
		if (savedPosition) {
			return goTo(savedPosition.y, options);
		}

		if (to.hash) {
			// if we're scrolling on the same page - perform direct scroll
			if (from.name === to.name) {
				return goTo(to.hash, options);
			} else {
				// if we are redirecting to another page and performing "scroll" operation
				// delayed scroll - ensure the event of page redirection is performed
				// before the scroll event is triggered
				// Poteintial improvement: replace timeout with event-driven approach, where
				// scroll is triggered after page load.
				return new Promise((resolve) => {
					setTimeout(() => {
						const element = document.querySelector(to.hash);
						if (element) {
							// Ensure the element exists and then use goTo to scroll to it
							goTo(element, {
								duration: 500
							}).then(() => {
								resolve();
							});
						} else {
							// Fallback if no element found
							resolve({ x: 0, y: 0 });
						}
					}, 500); // Delay
				});
			}
		}
		return goTo(scrollTo, { ...options, duration: 0 });
	}
});

router.beforeEach(async (to, from, next) => {
	// skip on server side
	if (!process.client) {
		next();
		return;
	}

	if (to.name === routesNames.agb) {
		if (MISC_DATA.basedata.agb_link) {
			window.location.href = MISC_DATA.basedata.agb_link;
			return;
		}
	}

	if (to.name === routesNames.agb_abo) {
		if (MISC_DATA.basedata.agb_abo_link && process.client) {
			window.location.href = MISC_DATA.basedata.agb_abo_link;
			return;
		}
	}

	if (to.name === routesNames.agb_b2b) {
		if (MISC_DATA.basedata.agb_b2b_link && process.client) {
			window.location.href = MISC_DATA.basedata.agb_b2b_link;
			return;
		}
	}

	const $logger = UTILS.store.$logger; // Shhhhhh

	// Update the baseURL depending on domain here as well since it can happen (most of the time) before the basedata middleware...
	const bu = extractDomain(window.location.host);
	let api_base_url = $axios.defaults.baseURL;

	if (api_base_url) {
		api_base_url = api_base_url.replace('.at', `.${bu}`);
		$axios.defaults.baseURL = api_base_url;
	}

	if (to.query.dialog && UTILS.dialog_buffer.length === 0) {
		// user pressed back btn when outside of dialog and browser still has them in history
		$logger.console({ message: 'router: clear dialog queries, goto ' + to.path });
		next({ path: to.path });
		return;
	}

	// remove the from.query.dialog from the dialog_buffer (will also trigger with closeDialog)
	// as long as it is not the first dialog in the buffer
	// openDialog is always adding it and it happens before this function is run
	// this way we can be sure that this is run ONLY when user presses browser previous
	// since closeDialog does not remove child dialogs from buffer

	if (from.query.dialog && to.query.dialog) {
		if (from.query.dialog !== UTILS.dialog_buffer[0]) {
			UTILS.removeBuffedDialog(from.query.dialog);
		}
		if (from.query.dialog === UTILS.dialog_buffer[0] && !UTILS.dialog_buffer.includes(to.query.dialog)) {
			// The user used back browser otherwise buffer will have the second dialog buffed already
			UTILS.closeDialog(from.query.dialog);
		}
	}

	if (!USER.is_logged_in && !USER.cookie_checked) {
		// tries to log passively if cookie or stored token
		await USER.LOGIN_PASSIVE();
	}

	if (to.query.ml != null && to.query.ml !== '') {
		// If magic process fails redirect to login page
		if (!(await USER.VALIDATE_MAGIC_LINK(to.query.ml))) {
			// redirect to login with redirect path - there we show a message about the invalid magic link
			next({
				name: routesNames.login,
				query: { redirect: to.path, magic_link: 'invalid' }
			});
			return;
		}
		next(to.path); // validation succeded - next to path to get rid of ml query
		return;
	}

	if (!requiresAuthGuard(to, from, next)) {
		// return if Auth guard triggers
		return;
	}

	if (!customerIncompleteGuard(to, from, next)) {
		// redirect to info
		return;
	}

	// Is checkout route
	if (to.matched.some((record) => record.name === routesNames.checkout)) {
		if (!(await checkoutGuard(to, from, next))) {
			return;
		}
	}

	next(); // make sure to always call next()!
});

router.afterEach((to) => {
	if (!process.client) {
		return;
	}

	if (!to.query.dialog) {
		UTILS.resetDialogBuffer();
	}

	if (UTILS.page_loading) {
		UTILS.setPageLoader(false);
	}
});

export { routesNames, getBasePath, getRouteAliases, getRoutePath, getAllPaths, handlePath, router };
export function createRouter() {
	return router;
}
