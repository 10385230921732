import { Partner } from '@/types/truck';
import { AModel } from '@/types/class_helper';

export enum Manufacturer {
	VW = 'VW',
	Iveco = 'Iveco',
	Mercedes = 'Mercedes',
	Ford = 'Ford',
	Opel = 'Opel',
	Renault = 'Renault',
	Fiat = 'Fiat',
	Peugot = 'Peugot',
	Nissan = 'Nissan',
	Toyota = 'Toyota',
	Citroen = 'Citroen',
	Daewoo = 'Daewoo',
	Daihatsu = 'Daihatsu',
	Honda = 'Honda',
	Hyundai = 'Hyundai',
	MAN = 'MAN'
}

export enum FuelType {
	Petrol = 'PETROL',
	Diesel = 'DIESEL'
}

export enum HookConnectorType {
	Con7 = '7',
	Con13 = '13'
}

export enum ShiftType {
	Manual = 'MANUAL',
	Automatic = 'AUTOMATIC'
}

export class Model extends AModel {
	id: string;
	name: string;
	size_group: string;
	manufacturer: Manufacturer;
	keyless_go: boolean;
	model: string;
	in_use: boolean;
	service_interval: {
		mileage: number;
		months: number;
	};

	warranty: {
		mileage: number;
		months: number;
		name: string;
		phone: string;
	};

	tire_dimension: string;
	measurements: {
		outside: {
			length: number;
			width: number;
			height: number;
		};
		inside: {
			length: number;
			width: number;
			height: number;
		};
		loading_height: number;
		narrowest_point: number;
		back_opening: {
			width: number;
			height: number;
		};
		codriver_side_opening: {
			width: number;
			height: number;
		};
		driver_side_opening: {
			width: number;
			height: number;
		};
	};

	weight: {
		self: number;
		additional: number;
		total: number;
	};

	fuel: {
		capacity: number;
		type: FuelType;
	};

	seats: number;
	shift_type: ShiftType;
	fastening_eyes: number;
	extras: {
		air_conditioning: boolean;
		hook: {
			exists: boolean;
			weight_braked: number;
			weight_not_braked: number;
			connector: HookConnectorType | null;
		};
		lift: {
			exists: boolean;
			max_load: number;
		};
		rack: {
			exists: boolean;
			max_load: number;
		};
	};

	pictures: {
		[index: string]: string | null;

		front: string | null;
		front_driver: string | null;
		driver: string | null;
		co_driver: string | null;
		back: string | null;
		drivers_cab: string | null;
		driver_open: string | null;
		co_driver_open: string | null;
	};

	documents: {
		[index: string]: string | null;

		manual: string | null;
	};

	partner: string | Partner | null;

	constructor(opt?: Partial<Model>) {
		super();

		// Default values (all props)
		this.id = '';
		this.name = '';
		this.size_group = '';
		this.manufacturer = Manufacturer.Ford;
		this.keyless_go = false;
		this.model = '';
		this.in_use = false;

		this.service_interval = {
			mileage: 40000,
			months: 24
		};
		this.warranty = {
			mileage: 40000,
			months: 24,
			name: '',
			phone: ''
		};
		this.tire_dimension = '';
		this.measurements = {
			outside: {
				length: 0,
				width: 0,
				height: 0
			},
			inside: {
				length: 0,
				width: 0,
				height: 0
			},
			loading_height: 0,
			narrowest_point: 0,
			back_opening: {
				width: 0,
				height: 0
			},
			codriver_side_opening: {
				width: 0,
				height: 0
			},
			driver_side_opening: {
				width: 0,
				height: 0
			}
		};
		this.weight = {
			self: 0,
			additional: 0,
			total: 0
		};
		this.fuel = {
			capacity: 80,
			type: FuelType.Diesel
		};
		this.seats = 3;
		this.shift_type = ShiftType.Manual;
		this.fastening_eyes = 0;
		this.extras = {
			air_conditioning: true,
			hook: {
				exists: false,
				weight_braked: 0,
				weight_not_braked: 0,
				connector: null
			},
			lift: {
				exists: false,
				max_load: 0
			},
			rack: {
				exists: false,
				max_load: 0
			}
		};

		this.partner = null;

		this.pictures = {
			front: null,
			front_driver: null,
			driver: null,
			co_driver: null,
			back: null,
			drivers_cab: null,
			driver_open: null,
			co_driver_open: null
		};
		this.documents = {
			manual: null
		};

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Model, Partial<Model>>(this, sanitized);

			if (this.partner && typeof this.partner !== 'string') {
				this.partner = new Partner(this.partner);
			}
		}
	}

	copy(): Model {
		const copy = new Model(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const dto = this.toObject();
		delete dto.size_group;

		if (this.partner instanceof Partner) {
			dto.partner = this.partner.id;
		}

		return dto;
	}
}

export default Model;
