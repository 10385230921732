import { AModel } from '@/types/class_helper';

export class Truck extends AModel {
	id: string;
	bu: string;
	number: number;
	name: string;
	plate: string;
	color: string;
	vin: string;
	state: string;
	model: string;
	partner: string;
	batch: string;
	mileage: number;
	safety_valid_to: string;
	year_of_construction: number;
	has_stick_on: boolean;
	b2b_start_date: string | null;

	constructor(opt?: Partial<Truck>) {
		super();

		// Default values (all props)
		this.id = '';
		this.bu = '';
		this.number = 69;
		this.name = '';
		this.plate = '';
		this.color = '';
		this.vin = '';
		this.state = '';
		this.model = '';
		this.partner = '';
		this.batch = '';
		this.mileage = 420;
		this.safety_valid_to = '';
		this.year_of_construction = 1969;
		this.has_stick_on = false;
		this.b2b_start_date = null;

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Truck, Partial<Truck>>(this, sanitized);
		}
	}

	copy(): Truck {
		const copy = new Truck(this.toObject());
		return copy;
	}
}

export default Truck;
