import { AModel } from '@/types/class_helper';

export class Customer extends AModel {
	id: string;
	gender: string;
	firstname: string;
	lastname: string;
	fullname: string;
	date_of_birth: string;
	company: string;
	vat_number: number | string | null;
	registration_number: string | null;

	address: {
		[index: string]: string | null;

		country: string;
		postcode: string;
		city: string;
		street: string;
		houseno: string;
		addition: string | null
	};

	email: string;
	phone: string;
	state: string;
	newsletter: boolean;

	custno: {
		[index: string]: string | null;

		at: string | null;
		de: string | null;
	};

	flags: any[];
	is_complete: boolean;
	has_password: boolean;
	booking_count: number;
	accept_surveillance: boolean;
	identity_document: string;
	drivers_license: string;

	constructor(opt?: Partial<Customer>) {
		super();

		// Default values (all props)
		this.id = '';
		this.gender = '';
		this.firstname = '';
		this.lastname = '';
		this.fullname = '';
		this.date_of_birth = '';
		this.company = '';
		this.vat_number = 0;
		this.registration_number = ''

		this.address = {
			country: '',
			postcode: '',
			city: '',
			street: '',
			houseno: '',
			addition: null
		};

		this.email = '';
		this.phone = '';
		this.state = '';
		this.newsletter = false;

		this.custno = {
			at: '',
			de: ''
		};

		this.flags = [];
		this.is_complete = false;
		this.has_password = false;
		this.booking_count = 0;
		this.accept_surveillance = false;
		this.identity_document = '';
		this.drivers_license = '';

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Customer, Partial<Customer>>(this, sanitized);
		}
	}

	copy(): Customer {
		const copy = new Customer(this.toObject());
		return copy;
	}
}

export default Customer;
