export default async function (context: any): Promise<void> {
	const domain = context.$getDomain();
	const domainChanged = context.store.state.MISC_DATA.current_domain !== domain;
	const empty = context.store.state.MISC_DATA.media_articles.length < 1;

	if (empty || domainChanged) {
		context.$logger.console({ message: 'fetch Media Articles' });
		await context.store.dispatch('GET_MEDIA_ARTICLES', domain);
	}
}
