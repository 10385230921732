import Billing from './billing';
import { AModel } from '@/types/class_helper';

type International = {
	time_start: string,
	time_end: string,
	zone: number;
}

export class Upgrade extends AModel {
	id: string;
	booking: string;
	up_international: International[];
	up_package: string | null;
	up_driver: number;
	time_start: string;
	time_end: string;
	state: string;
	voucher: boolean | null;
	origin: string | null;

	billing: Billing | undefined;
	pay_methods: string[];

	constructor(opt?: Partial<Upgrade>) {
		super();

		this.id = '';
		this.booking = '';
		this.up_international = [];
		this.up_package = '';
		this.up_driver = 0;
		this.time_start = '';
		this.time_end = '';
		this.state = '';
		this.voucher = null;
		this.origin = null;

		this.billing = undefined;
		this.pay_methods = [];

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Upgrade, Partial<Upgrade>>(this, sanitized);
		}
	}

	copy(): Upgrade {
		const copy = new Upgrade(this.toObject());
		return copy;
	}

	// toPayload(): Record<string, unknown> {
	// 	return {
	// 		truck: this.truck instanceof Truck ? this.truck.id : this.truck,
	// 		customer: this.customer instanceof Customer ? this.customer.id : this.customer,
	// 		time_start: this.time_start,
	// 		time_end: this.time_end,
	// 		upsells: {
	// 			package: this.upsells.package,
	// 			driver: this.upsells.driver,
	// 			cancel: this.upsells.cancel
	// 		}
	// 	}
	// }
}

export default Upgrade;
