export default async function ({ store, i18n, $getDomain }: any): Promise<void> {
	const default_per_domain = {
		at: 'de-AT',
		de: 'de-AT',
		cz: 'cs-CZ',
		hu: 'hu-HU'
	} as any

	const domain = $getDomain().toLowerCase()
	const locale = default_per_domain[domain]
	const [lang, bu] = default_per_domain[domain].split('-');

	const loaded_trans_keys = Object.keys(i18n.messages);
	const is_already_loaded = loaded_trans_keys.includes(locale) && typeof i18n.messages[locale] !== 'boolean';

	if (!is_already_loaded) {
		const translation = await store.dispatch('GET_TRANSLATION', { lang: lang, bu: bu.toLowerCase() });

		if (translation) {
			const meta = translation?.meta;
			// Generate Metadata objects for each entries in meta
			if (meta) {
				store.dispatch('GENERATE_META_DATA', meta);
			}
			// Store in VUEX to then merge current locale into it
			store.commit('storeTranslation', translation);
		}
	}
}
