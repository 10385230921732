import { Plugin } from '@nuxt/types';
import { GACustomEvent } from '@/types/misc_data';

import BOOKING from '@/store/modules/BookingModule';
import { Booking } from '@/types/booking/booking';
import { Billing } from '~/types/booking/billing';

export interface ITruckClickPayLoad {
	truck_id: string;
	site_id: string;
	lat: number;
	lng: number;
	page: number;
	num_pages: number;
	blocked_slots?: number;
	available_slots?: number;
}

export interface ISlotCLickedOrConfirmedPayload {
	truck_id: string;
	site_id: string;
	price: number;
	time_start: string;
	time_end: string;
	truck_size: string;
}

declare global {
	interface Window {
		gtag: Function | undefined;
	}
}

declare module 'vue/types/vue' {
	interface Vue {
		$tracking: {
			conversion: {
				booking: (booking: Booking) => void;
				waiver: (booking_id: string, value: number) => void;
				ltr_conversion: () => void;
				cancel_booking: (payload: { id: string; currency: string; total_net: number }) => void;
				extra_payment: (payload: { id: string; currency: string; total_net: number }) => void;
			};
			page_view: (title: string, location: string, path: string) => void;
			click: (category: string) => void;
			truck_click: (payload : ITruckClickPayLoad) => void;
			slot_clicked: (payload : ISlotCLickedOrConfirmedPayload) => void;
			slots_confirm: (payload : ISlotCLickedOrConfirmedPayload) => void;
			show: (category: string) => void;
			custom_event: (event_name: GACustomEvent, interaction?: boolean) => void;
		};
	}
}

declare module '@nuxt/types' {
	// nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
	interface NuxtAppOptions {
		$tracking: {
			conversion: {
				booking: (booking: Booking) => void;
				waiver: (booking_id: string, value: number) => void;
				ltr_conversion: () => void;
				cancel_booking: (payload: { id: string; currency: string; total_net: number }) => void;
				extra_payment: (payload: { id: string; currency: string; total_net: number }) => void;
			};
			page_view: (title: string, location: string, path: string) => void;
			click: (category: string) => void;
			truck_click: (payload : ITruckClickPayLoad) => void;
			slot_clicked: (payload : ISlotCLickedOrConfirmedPayload) => void;
			slots_confirm: (payload : ISlotCLickedOrConfirmedPayload) => void;
			show: (category: string) => void;
			custom_event: (event_name: GACustomEvent, interaction?: boolean) => void;
		};
	}
	// nuxtContext.$myInjectedFunction
	interface Context {
		$tracking: {
			conversion: {
				booking: (booking: Booking) => void;
				waiver: (booking_id: string, value: number) => void;
				ltr_conversion: () => void;
				cancel_booking: (payload: { id: string; currency: string; total_net: number }) => void;
				extra_payment: (payload: { id: string; currency: string; total_net: number }) => void;
			};
			page_view: (title: string, location: string, path: string) => void;
			click: (category: string) => void;
			truck_click: (payload : ITruckClickPayLoad) => void;
			slot_clicked: (payload : ISlotCLickedOrConfirmedPayload) => void;
			slots_confirm: (payload : ISlotCLickedOrConfirmedPayload) => void;
			show: (category: string) => void;
			custom_event: (event_name: GACustomEvent, interaction?: boolean) => void;
		};
	}
}

const trackingPlugin: Plugin = ({ app }, inject): void => {
	if (!app.$getDomain) {
		throw new Error('Please provide $getDomain. (tracking plugin)');
	}

	const bu = app.$getDomain();

	inject('tracking', {
		conversion: {
			booking: function (booking: Booking): void {
				if (!booking.id) {
					return;
				}

				app.$logger.console({
					message: 'Send ConversionBooking',
					data: { bu: bu, env: process.env.NODE_ENV, booking_tracking: booking.tracking?.booking }
				});

				if (process.env.NODE_ENV !== 'production' || !booking.tracking?.booking) {
					return;
				}

				if (!(booking.billing instanceof Billing) || !booking.billing || booking.tracking.booking.timestamp !== null) {
					return;
				}

				if (window.gtag) {
					window.gtag('event', 'conversion_booking', {
						value: booking.billing.total_net,
						currency: booking.billing.currency,
						transaction_id: booking.tracking.booking.trx_id
					});
				}

				BOOKING.TRACKING_DONE(booking.id);
			},

			waiver: function (transaction_id: string, value: number): void {
				app.$logger.console({
					message: 'Send WaiverBooking',
					data: { bu: bu, env: process.env.NODE_ENV, transaction_id: transaction_id }
				});

				if (process.env.NODE_ENV !== 'production') {
					return;
				}

				if (window.gtag) {
					window.gtag('event', 'conversion_booking', {
						value: value,
						currency: 'EUR',
						transaction_id: transaction_id
					});
				}
			},

			ltr_conversion: function (): void {
				app.$logger.console({
					message: 'Send LTR Conversion',
					data: { bu: bu, env: process.env.NODE_ENV }
				});

				if (process.env.NODE_ENV !== 'production') {
					return;
				}

				if (window.gtag) {
					window.gtag('event', 'conversion_ltr');
				}
			},

			cancel_booking: function (payload: { id: string; currency: string; total_net: number }): void {
				app.$logger.console({
					message: 'Send Cancel Booking Conversion',
					data: { bu: bu, env: process.env.NODE_ENV, payload: payload }
				});

				if (process.env.NODE_ENV !== 'production') {
					return;
				}

				if (window.gtag) {
					window.gtag('event', 'cancel_booking', {
						transaction_id: payload.id,
						currency: payload.currency,
						value: -Math.abs(payload.total_net)
					});
				}
			},

			extra_payment: function (payload: { id: string; currency: string; total_net: number }): void {
				app.$logger.console({
					message: 'Send Extra payment Conversion',
					data: { bu: bu, env: process.env.NODE_ENV, payload: payload }
				});

				if (process.env.NODE_ENV !== 'production') {
					return;
				}

				if (window.gtag) {
					window.gtag('event', 'conversion_special', {
						transaction_id: payload.id,
						currency: payload.currency,
						value: payload.total_net
					});
				}
			}
		},

		click: function (category: string): void {
			if (process.env.NODE_ENV !== 'production') {
				return;
			}

			if (window.gtag) {
				window.gtag('event', 'click', {
					event_category: category
				});
			}
		},

		truck_click: function (payload: ITruckClickPayLoad): void {

			app.$logger.console({
				message: 'GTM truck_clicks',
				data: payload
			});

			if (process.env.NODE_ENV !== 'production') {
				return;
			}

			if (window.gtag) {
				window.gtag('event', 'truck_clicks', payload);
			}
		},

		slot_clicked: function (payload: ISlotCLickedOrConfirmedPayload): void { // Triggered when a slot or a selection of slots are selected in the calendar
			if (process.env.NODE_ENV !== 'production') {
				return;
			}

			if (window.gtag) {
				window.gtag('event', 'slot_clicked', payload);
			}
		},

		slots_confirm: function (payload: ISlotCLickedOrConfirmedPayload): void { // Triggered when a slot selection is taken by the customer (clicked on book button)
			if (process.env.NODE_ENV !== 'production') {
				return;
			}

			if (window.gtag) {
				window.gtag('event', 'slot_confirm', payload);
			}
		},

		show: function (category: string): void {
			if (process.env.NODE_ENV !== 'production') {
				return;
			}
			if (window.gtag) {
				window.gtag('event', 'show', {
					event_category: category,
					non_interaction: true
				});
			}
		},

		custom_event: function (event_name: GACustomEvent, interaction?: boolean): void {
			if (process.env.NODE_ENV !== 'production') {
				return;
			}

			if (!Object.values(GACustomEvent).includes(event_name)) {
				// Check if it's an existing event name
				return;
			}

			if (window.gtag) {
				window.gtag('event', event_name, { non_interaction: !interaction });
			}
		}
	});
};

export default trackingPlugin;
