import Batch from './batch';
import { AModel } from '@/types/class_helper';

export class Partner extends AModel {
	id: string;
	bu: string;
	name: string;
	company: string;
	logo: string | null;

	districts: {
		id: string;
		name: string;
		country: string;
	}[];

	batches: Batch[];

	constructor(opt?: Partial<Partner>) {
		super();

		// Default values (all props)
		this.id = '';
		this.bu = '';
		this.name = '';
		this.company = '';
		this.logo = null;

		this.districts = [];
		this.batches = [];

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Partner, Partial<Partner>>(this, sanitized);

			// Convert objects to classes
			if (this.batches.length > 0) {
				this.batches = this.batches.map((b) => {
					if (typeof b === 'string') {
						return b;
					}
					return new Batch(b);
				});
			}
		}
	}

	copy(): Partner {
		const copy = new Partner(this.toObject());
		return copy;
	}
}

export default Partner;
