
import Vue from 'vue'
import globalAws from '@/mixins/globalAws';
import currentDomain from '@/mixins/currentDomain';

export default Vue.extend({
  name: 'AppDownload',

  mixins: [currentDomain],

  props: {
    show: {
        type: Boolean,
        default: false,
    },
  },

  data () {
    return {
      showQrDialog: false,
      awsPath: globalAws,
      appLink: 'https://123t.eu/app'
    }
  },

  computed: {
		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

    domain(): string {
			return this.$getDomain();
		}
  },
})
