
import Vue from 'vue';

export default Vue.extend({
	name: 'Loader',

	props: {
		display: Boolean,
		title: String,
		subtitle: String,
		icon: String,
		loading: Boolean,
		color: {
			type: String,
			default: 'orange123 lighten-1'
		}
	},

	data() {
		return {};
	}
});
