
import Vue from 'vue';

import MISC_DATA from '@/store/modules/MiscDataModule';
import UTILS from '@/store/modules/UtilityModule';
import globalAws from '@/mixins/globalAws';

type VMenu = Vue & {
	isActive: boolean;
};

export default Vue.extend({

	name: 'LocaleSwitcher',

	data() {
		return {
			awsPath: globalAws,
		}
	},

	computed: {
		MISC_DATA: () => MISC_DATA,
		UTILS: () => UTILS,

		countries(): typeof MISC_DATA.current_locales {
			return MISC_DATA.current_locales.filter(lang =>
				lang.locale.slice(0,2) === 'en' || lang.locale.slice(0,2) === this.getCurrentLanguage
			);
		},

		getCurrentLanguage(): string {
			const domain = this.$getDomain();
			switch (domain) {
				case 'at': case 'de': return 'de';
				case 'cz': return 'cs';
				case 'hu': return 'hu';
				default: return 'en';
			}
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		flagFileName(): string {
			const loc = this.$i18n.locale;
			let fileName = '';

			Object.keys(this.countries).forEach((i: any) => {
				if (this.countries[i].locale === loc) {
					fileName = this.countries[i].flag_file_name;
				}
			});
			return fileName || 'at.svg';
		},

		menu(): VMenu {
			return this.$refs.menu as VMenu;
		}
	},

	methods: {
		localeClicked(locale: string) {
			this.$emit('locale-change', locale) // Runs through NavBar $listener -> handled in default.vue
			this.$emit('close');
			this.menu.isActive = false;
		}
	},
});
