import { AModel } from '@/types/class_helper';

export class Document extends AModel {
	type: string;
	name: string;
	link: string

	constructor(opt?: Partial<Document>) {
		super();

		// Default values (all props)
		this.type = '';
		this.name = '';
		this.link = '';

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Document, Partial<Document>>(this, sanitized);
		}
	}

	copy(): Document {
		const copy = new Document(this.toObject());
		return copy;
	}
}

export default Document;
