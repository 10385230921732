import { AModel } from '@/types/class_helper';

export class Tracking extends AModel {
	booking: {
		timestamp: string | null;
		trx_id: string
	};

	waiver: {
		trx_id: string | null
	};

	constructor(opt?: Partial<Tracking>) {
		super();

		// Default values (all props)
		this.booking = {
			timestamp: '',
			trx_id: ''
		}
		this.waiver = {
			trx_id: ''
		}

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Tracking, Partial<Tracking>>(this, sanitized);
		}
	}

	copy(): Tracking {
		const copy = new Tracking(this.toObject());
		return copy;
	}
}

export default Tracking;
