import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import loggerPlugin from '@/plugins/logger';

// Vuex decorators references & Docs
// https://github.com/championswimmer/vuex-module-decorators
// https://typescript.nuxtjs.org/cookbook/store/

Vue.use(Vuex);

interface RootState {}

export const plugins = [loggerPlugin];

export const store = new Vuex.Store<RootState>({
	actions: {
		nuxtServerInit: () => {}
	}
});

const createStore = (): Store<RootState> => {
	return store;
};

export default createStore;

// To use a module in components OR anywhere like in router

// import MODULE from '@/store/modules/MyModule'

// export default vue.extend ({
//     created() {
//         console.log('created');
//         console.log(MODULE) <- Module is ready and available
//     },
// })
