import { handlePath } from '@/router';
import { Routes } from '@/router/routes';

// This middleware handles client-side url translations. There is a serverMiddleware doing the same thing and redirected with proper 308 code
// But this one is still needed for client-side router.push etc...
export default function ({ route, redirect, $getDomain }: any): void {
	const next_path = handlePath({
		src_path: route.fullPath,
		tld: $getDomain(),
		routes: Routes,
		return_obj: true
	})

	if (typeof next_path !== 'string' && (next_path.path !== route.path)) {
		redirect(next_path);
	}
}
