import { AModel } from '@/types/class_helper';

export class Driver extends AModel {
	id: string;
	booking: string;
	fullname: string;
	is_main: boolean;
	drivers_license: string;
	gender: string;
	firstname: string;
	lastname: string;
	date_of_birth: string;

	address: {
		[index: string]: string | null;

		country: string;
		postcode: string;
		city: string;
		street: string;
		houseno: string;
		addition: string | null
	};

	constructor(opt?: Partial<Driver>) {
		super();

		// Default values (all props)
		this.id = '';
		this.booking = '';
		this.fullname = '';
		this.is_main = false;
		this.drivers_license = '';
		this.gender = '';
		this.firstname = '';
		this.lastname = '';
		this.date_of_birth = '';

		this.address = {
			country: '',
			postcode: '',
			city: '',
			street: '',
			houseno: '',
			addition: null
		};

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Driver, Partial<Driver>>(this, sanitized);
		}
	}

	copy(): Driver {
		const copy = new Driver(this.toObject());
		return copy;
	}
}

export default Driver;
