const gmapStyle = [
   {
     "elementType": "geometry",
     "stylers": [
       {
         "color": "#f5f5f5"
       }
     ]
   },
   {
     "elementType": "labels.text.fill",
     "stylers": [
       {
         "color": "#616161"
       }
     ]
   },
   {
     "elementType": "labels.text.stroke",
     "stylers": [
       {
         "color": "#f5f5f5"
       }
     ]
   },
   {
     "featureType": "administrative.locality",
     "elementType": "labels.text",
     "stylers": [
       {
         "visibility": "on"
       },
     ]
   },
   {
     "featureType": "administrative.country",
     "elementType": "geometry.stroke",
     "stylers": [
       {
         "color": "#392F5A"
       }
     ]
   },
   {
     "featureType": "administrative.land_parcel",
     "elementType": "labels.text.fill",
     "stylers": [
       {
         "color": "#bdbdbd"
       }
     ]
   },
	{
		"featureType": "landscape.man_made", // THIS IS SOMEHOW ALL ICONS LIKE TOILETS/ELEVATORS/AND STUFF
		"elementType": "labels.icon",
		"stylers": [
		  {
			 "visibility": "off"
		  }
		]
	},
	{
		"featureType": "landscape.natural",
		"elementType": "labels.icon",
		"stylers": [
		  {
			 "visibility": "off"
		  }
		]
	 },
   {
     "featureType": "landscape.natural.terrain",
     "elementType": "labels.text",
     "stylers": [
       {
         "saturation": -100
       },
       {
         "weight": 2.5
       }
     ]
   },
   {
     "featureType": "poi",
     "stylers": [
       {
         "visibility": "off"
       }
     ]
	},
   {
     "featureType": "transit",
     "stylers": [
       {
         "visibility": "off"
       }
     ]
   },
   {
     "featureType": "road",
     "elementType": "labels.icon",
     "stylers": [
       {
         "visibility": "off"
       }
     ]
   },
   {
     "featureType": "road",
     "elementType": "geometry",
     "stylers": [
       {
         "color": "#ffffff"
       }
     ]
   },
   {
     "featureType": "road.arterial",
     "elementType": "labels.text.fill",
     "stylers": [
       {
         "color": "#757575"
       }
     ]
   },
   {
     "featureType": "road.highway",
     "elementType": "geometry",
     "stylers": [
       {
         "color": "#dadada"
       }
     ]
   },
   {
     "featureType": "road.highway",
     "elementType": "geometry.fill",
     "stylers": [
       {
         "saturation": -50
       },
       {
         "lightness": -15
       },
       {
         "weight": 0.5
       }
     ]
   },
   {
     "featureType": "road.highway",
     "elementType": "labels.text.fill",
     "stylers": [
       {
         "color": "#616161"
       }
     ]
   },
   {
     "featureType": "road.local",
     "elementType": "labels.text.fill",
     "stylers": [
       {
         "color": "#9e9e9e"
       }
     ]
   },
   {
     "featureType": "water",
     "elementType": "geometry",
     "stylers": [
       {
         "color": "#c9c9c9"
       }
     ]
   },
   {
     "featureType": "water",
     "elementType": "geometry.fill",
     "stylers": [
       {
         "color": "#5fbec3"
       },
       {
         "lightness": 40
       }
     ]
   },
   {
     "featureType": "water",
     "elementType": "labels.text.fill",
     "stylers": [
       {
         "color": "#9e9e9e"
       }
     ]
   }
 ]

 export { gmapStyle }