import Truck from './truck';
import Customer from './customer';
import Driver from './driver';
import Tracking from './tracking';
import Document from './document';
import Deposit from './deposit';
import Billing from './billing';
import { AModel } from '@/types/class_helper';

export type International = {
  [index: string]: string | number | boolean | null;

  id: string | null;
  booking: string | null;
  time_start: string;
  time_end: string;
  zone: number;
  country: any;
  was_fine: boolean | null;
};

export interface BookingChange {
  is_canceled: boolean;
  can_cancel: boolean;
  can_move: boolean;
  cancel_percentage: string | number | null;
  cancel_time: string | null;
  cancel_type: string | null;
  vouchers: string[];
}

export class Booking extends AModel {
  id: string;
  bu: string;
  number: string;
  truck: Truck | string | null;
  customer: Customer | string | null;
  time_start: string;
  time_end: string;
  state: string;
  force_app: boolean;
  user_is_driver: boolean | null;
  deposit: Deposit | null;
  voucher_booking: any;
  voucher_waiver: any;
  customer_source: string;
  customer_profession: string | null;
  upsells: {
    international: boolean;
    package: string;
    driver: number;
    cancel: boolean;
  };

  international: International[];
  driver: Driver[];
  step: {
    current: string;
    info: any[] | string | null;
    previous: string;
    next: string | null;
  };

  billing: Billing | null;
  documents: Document[];
  tracking: Tracking | null;

  change?: BookingChange;

  time_extends?: {
    min: string;
    max: string;
  };

  constructor(opt?: Partial<Booking>) {
    super();

    // Default values (all props)
    this.id = '';
    this.bu = '';
    this.number = '';
    this.truck = null;
    this.customer = null;
    this.time_start = '';
    this.time_end = '';
    this.state = '';
    this.force_app = true;
    this.user_is_driver = true;
    this.deposit = null;
    this.voucher_booking = null;
    this.voucher_waiver = null;
    this.customer_source = '';
    this.customer_profession = '';
    this.upsells = {
      international: false,
      package: '',
      driver: 0,
      cancel: false,
    };
    this.international = [];
    this.driver = [];
    this.step = {
      current: '',
      info: '',
      previous: '',
      next: '',
    };
    this.billing = null;
    this.documents = [];
    this.tracking = null;
    this.time_extends = undefined;
    this.change = undefined;

    if (opt) {
      // Sanitize the input to keep only the fields that are needed for this model
      const sanitized = Object.assign(
        {},
        ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] }))
      );

      // Assign values passed to the constructor
      Object.assign<Booking, Partial<Booking>>(this, sanitized);

      // Instanciate child classes if needed
      if (this.truck && typeof this.truck !== 'string') {
        this.truck = new Truck(this.truck);
      }
      if (this.customer && typeof this.customer !== 'string') {
        this.customer = new Customer(this.customer);
      }
      if (this.deposit !== null && typeof this.deposit !== 'string') {
        this.deposit = new Deposit(this.deposit);
      }
      if (this.driver.length > 0) {
        this.driver = this.driver.map((driver) => new Driver(driver));
      }
      if (this.billing !== null) {
        this.billing = new Billing(this.billing);
      }
      if (this.documents.length > 0) {
        this.documents = this.documents.map((doc) => new Document(doc));
      }
      if (this.tracking !== null) {
        this.tracking = new Tracking(this.tracking);
      }
    }
  }

  copy(): Booking {
    const copy = new Booking(this.toObject());
    return copy;
  }

  toPayload(): Record<string, unknown> {
    return {
      truck: this.truck instanceof Truck ? this.truck.id : this.truck,
      customer: this.customer instanceof Customer ? this.customer.id : this.customer,
      time_start: this.time_start,
      time_end: this.time_end,
      upsells: {
        package: this.upsells.package,
        driver: this.upsells.driver,
        cancel: this.upsells.cancel,
      },
    };
  }

  toZonePayloadList(): Partial<International>[] | undefined {
    if (!this.international || this.international?.length < 1) {
      return undefined;
    }

    return this.international.map((zone: International) => {
      return {
        time_start: zone.time_start,
        time_end: zone.time_end,
        zone: zone.zone,
      };
    });
  }
}

export default Booking;
