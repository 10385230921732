import { NavigationGuard } from 'vue-router/types';
import { checkoutStepRedirector } from '@/router/routes';

import CART from '@/store/modules/CartModule';
import BOOKING from '@/store/modules/BookingModule';
import { routesNames } from '@/router';
import USER from '@/store/modules/UserModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import UTILS from '@/store/modules/UtilityModule';

import { ErrorResponse } from '~/types/api_helper';
import { Booking } from '@/types/booking/booking';

/**
 * returns false if redirect to login page has happened
 *
 * @param to
 * @param from
 * @param next
 * @returns
 */
const requiresAuthGuard: NavigationGuard = (to, _from, next) => {
	// skip on server side
	if (!process.client) {
		next();
		return false;
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!USER.is_logged_in) {
			next({
				name: routesNames.login,
				query: { redirect: to.fullPath }
			});

			return false;
		}
	}
	return true;
};

const customerIncompleteGuard: NavigationGuard = (to, from, next) => {
	// skip on server side
	if (!process.client) {
		return false;
	}

	const user = USER.data;
	// const domain = MISC_DATA.current_domain;

	if (to.name !== routesNames.info && to.name) {
		if (!user.is_complete && user.booking_count > 0) {
			next({
				name: routesNames.info,
				query: { redirect: to.fullPath }
			});
			return false;
		}
	} else if (user.is_complete && from.name) {
		next({ name: from.name || routesNames.home });
		return false;
	}
	return true;
};

const checkoutGuard: NavigationGuard = async (to, from, next) => {
	// skip on server side
	if (!process.client) {
		next();
		return false;
	}

	const $logger = (UTILS as any).store.$logger; // Shhhhh

	const booking_id = to.params.id;
	$logger.console({ message: `GUARD - checkoutGuard - booking ID : ${booking_id}` });

	if (to.params.id === null) {
		return true;
	}

	// Always get booking to be sure we redirect on the correct step
	$logger.console({ message: 'Booking being requested from server...' });
	const res = await BOOKING.GET_BOOKING({ id: booking_id });

	if (res instanceof ErrorResponse) {
		if (res.code === 403 && res.type === 'INVALID_GRANT') {
			next({
				name: routesNames.login,
				query: { redirect: to.fullPath }
			});
			return false;
		}

		$logger.console({ message: `Error with booking ${booking_id} - going home & clearing everything` });
		await CART.CLEAR_BOOKING_STORED();
		await BOOKING.CLEAR_BOOKING_STORED();
		next({ name: routesNames.home });
		return false;
	}

	const booking = new Booking(res);

	// No change skip step validation
	if (from.name === to.name) {
		// console.log('same route - stopping guard');
		next();
		return false;
	}

	const domain = MISC_DATA.current_domain;
	let booking_bu;
	if (booking.bu) {
		booking_bu = booking.bu.toLowerCase() as string;
	}

	// Check if booking is in current bu otherwise redirect there
	if (booking_bu && booking_bu !== domain) {
		UTILS.setRedirectLoader(true);
		const base_url = MISC_DATA.base_url[booking_bu];
		$logger.console({ message: `redirect to correct domain: ${base_url}/booking/${booking_id}` });
		window.location.href = `${base_url}/booking/${booking_id}`;
		return false;
	}

	// No client set in booking from server
	if (!booking.customer) {
		await CART.STORE_BOOKING_DATA(booking); // ????
	}

	// Check booking validity
	if (!(await CART.CHECK_BOOKING(booking)) || (USER.is_logged_in && USER.data.id !== booking.customer)) {
		// Do we need to check user id against customer ID again?
		$logger.console({ message: 'Booking expired/invalid or user does not match with booking client' });
		// Maybe it could check this on the login page before redirecting and inform user that
		// the booking does not belong to him -> To login again with correct account or back home
		CART.CLEAR_BOOKING_STORED();
		BOOKING.CLEAR_BOOKING_STORED();
		next({ name: routesNames.home });
		return false;
	}

	if (booking.step.current) {
		const redirect_to = checkoutStepRedirector[booking.step.current.toLowerCase()];
		if (to.name !== redirect_to) {
			$logger.console({ message: 'target route name is different than redirect_to - redirecting...' });
			// Replace to skip saving the original redirect to the browser history -- Not needed anymore ? It introduce problems cause we need a next here
			next({ name: redirect_to, params: { id: booking.id as string } });
			return false;
		}
	} else {
		$logger.console({ message: 'No booking or current step - going home (should clear all bookings stored)' });
		await CART.CLEAR_BOOKING_STORED();
		await BOOKING.CLEAR_BOOKING_STORED();
		next({ name: routesNames.home });
		return false;
	}
	return true;
};

export { requiresAuthGuard, checkoutGuard, customerIncompleteGuard };
