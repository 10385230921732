import { AModel } from '@/types/class_helper';

export class Deposit extends AModel {
	type: string | null;
	state: string | null;
	voucher: {
		code: string |null;
		value: number | null;
	};

	refund: {
		type: string |null;
		amount: number | null;
		date_request: string |null;
		date_nbf: string |null;
		date_done: string |null;
		require_bank_details: boolean | null;
	};

	constructor(opt?: Partial<Deposit>) {
		super();

		// Default values (all props)
		this.type = '';
		this.state = '';
		this.voucher = {
			code: '',
			value: 0
		};
		this.refund = {
			type: '',
			amount: 0,
			date_request: '',
			date_nbf: '',
			date_done: '',
			require_bank_details: null
		};

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Deposit, Partial<Deposit>>(this, sanitized);
		}
	}

	copy(): Deposit {
		const copy = new Deposit(this.toObject());
		return copy;
	}
}

export default Deposit;
