import { getRoutePath, routesNames } from '@/router';

export default function ({ $logger, $getDomain, store, route, redirect }: any): void {
	$logger.console({ message: 'Article middleware' });

	const path = route.params.pathMatch;
	const article_list = store.state.MISC_DATA.press_list;

	// current article
	const article = article_list.filter((art: any) => art.url_slug === path)[0] as any;

	if (!article) {
		// path is wrong or article inexistant
		$logger.console({ message: 'No article or wrong URL -> 404' });
		return redirect({ path: getRoutePath(routesNames.page_not_found, $getDomain()) });
	}

	const article_meta = article.meta as any;

	const current_press_meta = store.state.MISC_DATA.meta_data.pressarticle;

	// update the MISC_DATA.meta_data.pressarticle with article_meta
	if (!current_press_meta || (current_press_meta && current_press_meta.title !== article_meta.title)) {
		const title = article_meta.title ?? article.title;
		const desc = article_meta.description ?? '123-Transporter - Alle Neuigkeiten und Unternehmens-News auf einem Blick.';

		// generate the meta data object and store it in the misc data module
		store.commit('addMetaData', { key: 'pressarticle', title: title, desc: desc });
	}
}
