export const AbtestWrapper = () => import('../../components/abtest/wrapper.vue' /* webpackChunkName: "components/abtest-wrapper" */).then(c => wrapFunctional(c.default || c))
export const AuthAccountForm = () => import('../../components/auth/AccountForm.vue' /* webpackChunkName: "components/auth-account-form" */).then(c => wrapFunctional(c.default || c))
export const AuthLoginForm = () => import('../../components/auth/LoginForm.vue' /* webpackChunkName: "components/auth-login-form" */).then(c => wrapFunctional(c.default || c))
export const AuthPasswordForm = () => import('../../components/auth/PasswordForm.vue' /* webpackChunkName: "components/auth-password-form" */).then(c => wrapFunctional(c.default || c))
export const BookingTimeExtension = () => import('../../components/booking/BookingTimeExtension.vue' /* webpackChunkName: "components/booking-time-extension" */).then(c => wrapFunctional(c.default || c))
export const BookingExtraCountryCard = () => import('../../components/booking/ExtraCountryCard.vue' /* webpackChunkName: "components/booking-extra-country-card" */).then(c => wrapFunctional(c.default || c))
export const BookingExtraDriverPicker = () => import('../../components/booking/ExtraDriverPicker.vue' /* webpackChunkName: "components/booking-extra-driver-picker" */).then(c => wrapFunctional(c.default || c))
export const BookingExtraZonePicker = () => import('../../components/booking/ExtraZonePicker.vue' /* webpackChunkName: "components/booking-extra-zone-picker" */).then(c => wrapFunctional(c.default || c))
export const BookingLicensePicturesExplained = () => import('../../components/booking/LicensePicturesExplained.vue' /* webpackChunkName: "components/booking-license-pictures-explained" */).then(c => wrapFunctional(c.default || c))
export const BookingPaymentCardsExplained = () => import('../../components/booking/PaymentCardsExplained.vue' /* webpackChunkName: "components/booking-payment-cards-explained" */).then(c => wrapFunctional(c.default || c))
export const BookingPersonInfoCard = () => import('../../components/booking/PersonInfoCard.vue' /* webpackChunkName: "components/booking-person-info-card" */).then(c => wrapFunctional(c.default || c))
export const BookingUpsellPackageCard = () => import('../../components/booking/UpsellPackageCard.vue' /* webpackChunkName: "components/booking-upsell-package-card" */).then(c => wrapFunctional(c.default || c))
export const BookingVoucherForm = () => import('../../components/booking/VoucherForm.vue' /* webpackChunkName: "components/booking-voucher-form" */).then(c => wrapFunctional(c.default || c))
export const DialogsMobileAppQr = () => import('../../components/dialogs/MobileAppQr.vue' /* webpackChunkName: "components/dialogs-mobile-app-qr" */).then(c => wrapFunctional(c.default || c))
export const HomeAdvantages = () => import('../../components/home/Advantages.vue' /* webpackChunkName: "components/home-advantages" */).then(c => wrapFunctional(c.default || c))
export const HomeCustomerReviews = () => import('../../components/home/CustomerReviews.vue' /* webpackChunkName: "components/home-customer-reviews" */).then(c => wrapFunctional(c.default || c))
export const HomeHowToVideo = () => import('../../components/home/HowToVideo.vue' /* webpackChunkName: "components/home-how-to-video" */).then(c => wrapFunctional(c.default || c))
export const HomeKnownFrom = () => import('../../components/home/KnownFrom.vue' /* webpackChunkName: "components/home-known-from" */).then(c => wrapFunctional(c.default || c))
export const HomeLocationList = () => import('../../components/home/LocationList.vue' /* webpackChunkName: "components/home-location-list" */).then(c => wrapFunctional(c.default || c))
export const HomeOfficialPartners = () => import('../../components/home/OfficialPartners.vue' /* webpackChunkName: "components/home-official-partners" */).then(c => wrapFunctional(c.default || c))
export const HomeOfficialPartnersText = () => import('../../components/home/OfficialPartnersText.vue' /* webpackChunkName: "components/home-official-partners-text" */).then(c => wrapFunctional(c.default || c))
export const HomePartners = () => import('../../components/home/Partners.vue' /* webpackChunkName: "components/home-partners" */).then(c => wrapFunctional(c.default || c))
export const HomePricing = () => import('../../components/home/Pricing.vue' /* webpackChunkName: "components/home-pricing" */).then(c => wrapFunctional(c.default || c))
export const HomePricingCard = () => import('../../components/home/PricingCard.vue' /* webpackChunkName: "components/home-pricing-card" */).then(c => wrapFunctional(c.default || c))
export const HomeProtectionPackageCard = () => import('../../components/home/ProtectionPackageCard.vue' /* webpackChunkName: "components/home-protection-package-card" */).then(c => wrapFunctional(c.default || c))
export const HomeProtectionPackages = () => import('../../components/home/ProtectionPackages.vue' /* webpackChunkName: "components/home-protection-packages" */).then(c => wrapFunctional(c.default || c))
export const HomeSummary123 = () => import('../../components/home/Summary123.vue' /* webpackChunkName: "components/home-summary123" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterBooking = () => import('../../components/layout/FooterBooking.vue' /* webpackChunkName: "components/layout-footer-booking" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterMain = () => import('../../components/layout/FooterMain.vue' /* webpackChunkName: "components/layout-footer-main" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavBarTop = () => import('../../components/layout/NavBarTop.vue' /* webpackChunkName: "components/layout-nav-bar-top" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavBarTopFocus = () => import('../../components/layout/NavBarTopFocus.vue' /* webpackChunkName: "components/layout-nav-bar-top-focus" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageHeader = () => import('../../components/layout/PageHeader.vue' /* webpackChunkName: "components/layout-page-header" */).then(c => wrapFunctional(c.default || c))
export const MapBanner = () => import('../../components/map/MapBanner.vue' /* webpackChunkName: "components/map-banner" */).then(c => wrapFunctional(c.default || c))
export const MapChooseSizeNew = () => import('../../components/map/MapChooseSizeNew.vue' /* webpackChunkName: "components/map-choose-size-new" */).then(c => wrapFunctional(c.default || c))
export const MapHeader = () => import('../../components/map/MapHeader.vue' /* webpackChunkName: "components/map-header" */).then(c => wrapFunctional(c.default || c))
export const MapPerLocation = () => import('../../components/map/MapPerLocation.vue' /* webpackChunkName: "components/map-per-location" */).then(c => wrapFunctional(c.default || c))
export const MapTruckCardNew = () => import('../../components/map/MapTruckCardNew.vue' /* webpackChunkName: "components/map-truck-card-new" */).then(c => wrapFunctional(c.default || c))
export const MapTruckCarouselNew = () => import('../../components/map/MapTruckCarouselNew.vue' /* webpackChunkName: "components/map-truck-carousel-new" */).then(c => wrapFunctional(c.default || c))
export const MapTruckListItem = () => import('../../components/map/MapTruckListItem.vue' /* webpackChunkName: "components/map-truck-list-item" */).then(c => wrapFunctional(c.default || c))
export const MapWrapper = () => import('../../components/map/MapWrapper.vue' /* webpackChunkName: "components/map-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MapSelectTruckSize = () => import('../../components/map/SelectTruckSize.vue' /* webpackChunkName: "components/map-select-truck-size" */).then(c => wrapFunctional(c.default || c))
export const MapTruckPrice = () => import('../../components/map/TruckPrice.vue' /* webpackChunkName: "components/map-truck-price" */).then(c => wrapFunctional(c.default || c))
export const PressArticle = () => import('../../components/press/PressArticle.vue' /* webpackChunkName: "components/press-article" */).then(c => wrapFunctional(c.default || c))
export const PressList = () => import('../../components/press/PressList.vue' /* webpackChunkName: "components/press-list" */).then(c => wrapFunctional(c.default || c))
export const ProfileBookingListCard = () => import('../../components/profile/BookingListCard.vue' /* webpackChunkName: "components/profile-booking-list-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileChangePassword = () => import('../../components/profile/ChangePassword.vue' /* webpackChunkName: "components/profile-change-password" */).then(c => wrapFunctional(c.default || c))
export const ProfileInvoiceListCard = () => import('../../components/profile/InvoiceListCard.vue' /* webpackChunkName: "components/profile-invoice-list-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileDashboard = () => import('../../components/profile/ProfileDashboard.vue' /* webpackChunkName: "components/profile-dashboard" */).then(c => wrapFunctional(c.default || c))
export const ProfileUserProfile = () => import('../../components/profile/UserProfile.vue' /* webpackChunkName: "components/profile-user-profile" */).then(c => wrapFunctional(c.default || c))
export const TruckInfoNew = () => import('../../components/truck/TruckInfoNew.vue' /* webpackChunkName: "components/truck-info-new" */).then(c => wrapFunctional(c.default || c))
export const TruckRepairCosts = () => import('../../components/truck/TruckRepairCosts.vue' /* webpackChunkName: "components/truck-repair-costs" */).then(c => wrapFunctional(c.default || c))
export const GeneralCalendarSlotGridNew = () => import('../../components/general/calendar/SlotGridNew.vue' /* webpackChunkName: "components/general-calendar-slot-grid-new" */).then(c => wrapFunctional(c.default || c))
export const GeneralCalendarSlotNew = () => import('../../components/general/calendar/SlotNew.vue' /* webpackChunkName: "components/general-calendar-slot-new" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsQrCodeScan = () => import('../../components/general/icons/QrCodeScan.vue' /* webpackChunkName: "components/general-icons-qr-code-scan" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckAvailable = () => import('../../components/general/icons/TruckAvailable.vue' /* webpackChunkName: "components/general-icons-truck-available" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckAvailablePink = () => import('../../components/general/icons/TruckAvailablePink.vue' /* webpackChunkName: "components/general-icons-truck-available-pink" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckBackLeftCorner = () => import('../../components/general/icons/TruckBackLeftCorner.vue' /* webpackChunkName: "components/general-icons-truck-back-left-corner" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckBackRightCorner = () => import('../../components/general/icons/TruckBackRightCorner.vue' /* webpackChunkName: "components/general-icons-truck-back-right-corner" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckDriverCabin = () => import('../../components/general/icons/TruckDriverCabin.vue' /* webpackChunkName: "components/general-icons-truck-driver-cabin" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckFrontLeftCorner = () => import('../../components/general/icons/TruckFrontLeftCorner.vue' /* webpackChunkName: "components/general-icons-truck-front-left-corner" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckFrontRightCorner = () => import('../../components/general/icons/TruckFrontRightCorner.vue' /* webpackChunkName: "components/general-icons-truck-front-right-corner" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckLoadingBay = () => import('../../components/general/icons/TruckLoadingBay.vue' /* webpackChunkName: "components/general-icons-truck-loading-bay" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconsTruckUnavailable = () => import('../../components/general/icons/TruckUnavailable.vue' /* webpackChunkName: "components/general-icons-truck-unavailable" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsBookingExtraPill = () => import('../../components/general/utils/BookingExtraPill.vue' /* webpackChunkName: "components/general-utils-booking-extra-pill" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsCodeReader = () => import('../../components/general/utils/CodeReader.vue' /* webpackChunkName: "components/general-utils-code-reader" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsContactForm = () => import('../../components/general/utils/ContactForm.vue' /* webpackChunkName: "components/general-utils-contact-form" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsCountrySVGPath = () => import('../../components/general/utils/CountrySVGPath.vue' /* webpackChunkName: "components/general-utils-country-s-v-g-path" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsDatePicker = () => import('../../components/general/utils/DatePicker.vue' /* webpackChunkName: "components/general-utils-date-picker" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsDateTimePicker = () => import('../../components/general/utils/DateTimePicker.vue' /* webpackChunkName: "components/general-utils-date-time-picker" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsIdentityDocExplained = () => import('../../components/general/utils/IdentityDocExplained.vue' /* webpackChunkName: "components/general-utils-identity-doc-explained" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsInteractiveEuropeMap = () => import('../../components/general/utils/InteractiveEuropeMap.vue' /* webpackChunkName: "components/general-utils-interactive-europe-map" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsInvoiceBanner = () => import('../../components/general/utils/InvoiceBanner.vue' /* webpackChunkName: "components/general-utils-invoice-banner" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsJobCard = () => import('../../components/general/utils/JobCard.vue' /* webpackChunkName: "components/general-utils-job-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsLoader = () => import('../../components/general/utils/Loader.vue' /* webpackChunkName: "components/general-utils-loader" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsLocaleSwitcher = () => import('../../components/general/utils/LocaleSwitcher.vue' /* webpackChunkName: "components/general-utils-locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsMediaDownloadButton = () => import('../../components/general/utils/MediaDownloadButton.vue' /* webpackChunkName: "components/general-utils-media-download-button" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsPaymentDialog = () => import('../../components/general/utils/PaymentDialog.vue' /* webpackChunkName: "components/general-utils-payment-dialog" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsPriceTable = () => import('../../components/general/utils/PriceTable.vue' /* webpackChunkName: "components/general-utils-price-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsRibbonBanner = () => import('../../components/general/utils/RibbonBanner.vue' /* webpackChunkName: "components/general-utils-ribbon-banner" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsSectionHeading = () => import('../../components/general/utils/SectionHeading.vue' /* webpackChunkName: "components/general-utils-section-heading" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsSelectDropdown = () => import('../../components/general/utils/SelectDropdown.vue' /* webpackChunkName: "components/general-utils-select-dropdown" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsSnackbar = () => import('../../components/general/utils/Snackbar.vue' /* webpackChunkName: "components/general-utils-snackbar" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsSummaryPanel = () => import('../../components/general/utils/SummaryPanel.vue' /* webpackChunkName: "components/general-utils-summary-panel" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsTeamMembers = () => import('../../components/general/utils/TeamMembers.vue' /* webpackChunkName: "components/general-utils-team-members" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsUpgradeBookingDates = () => import('../../components/general/utils/UpgradeBookingDates.vue' /* webpackChunkName: "components/general-utils-upgrade-booking-dates" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsUpsellsDisplayBar = () => import('../../components/general/utils/UpsellsDisplayBar.vue' /* webpackChunkName: "components/general-utils-upsells-display-bar" */).then(c => wrapFunctional(c.default || c))
export const GeneralUtilsUpsellsDisplayIcon = () => import('../../components/general/utils/UpsellsDisplayIcon.vue' /* webpackChunkName: "components/general-utils-upsells-display-icon" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
