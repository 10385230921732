
import Vue from 'vue';

export default Vue.extend({
	name: 'ConfirmDialog',
	props: {
		message: {
			type: String,
			required: true
		},
		title: {
			type: String
		},
		color: {
			type: String,
			default: 'orange123--text'
		},
		rightBtnText: String,
		leftBtnText: String
	},

	data() {
		return {
			show: true
		};
	},

	computed: {
		titleDisplay() {
			return this.title ? this.title : this.$t('actions.confirm_title');
		}
	},

	methods: {
		clickYes() {
			this.show = false;
			this.$emit('click', true);
		},

		clickNo() {
			this.show = false;
			this.$emit('click', false);
		}
	}
});
