import { Module, VuexModule, VuexMutation, getModule, VuexAction } from 'nuxt-property-decorator';
import dayjs from 'dayjs';

import { store } from '@/store';
import { router } from '@/router';

import { dateToAustrianTimezone } from '@/_helpers/misc_helper';

import Booking from '@/types/booking/booking';
import { IDateTimeSearch } from '@/types/misc_data';
import { ErrorResponse } from '@/types/api_helper';

import USER from '@/store/modules/UserModule';
import BOOKING from '@/store/modules/BookingModule';

export enum SlotType {
	Default = 'default',
	Promo6 = 'promo',
	Offtime = 'offtime',
	Peaktime = 'peaktime',
	Peaktime2 = 'peaktime2'
}

@Module({
	name: 'CART',
	store, // this basically injects the module in the store dynamically thanks to next line
	dynamic: true,
	stateFactory: true // apparently necessary/better for Nuxt
})
class CART extends VuexModule {
	booking = new Booking();

	last_time_search = new IDateTimeSearch();
	slot_type = SlotType.Default
	insurance_promo = {} as any;

	date_time_picked = false;
	is_single_day = false;

	// ------------------------------------------------
	// ------------- Mutations ------------------------
	// ------------------------------------------------
	// Once decorated with the @Mutation decorator, Mutations are run with 'this' (context) set as the state.
	// So when you want to change things in the state, state.item++ is simply this.item++

	@VuexMutation
	storeBookingValues(payload: Booking) {
		// console.log('Payload booking in Cart module', payload)
		this.booking = payload;
		if (localStorage) {
			localStorage.setItem('booking', JSON.stringify(this.booking));
		}
	}

	@VuexMutation
	storeDateTimePicked(payload: { start: string; end: string }) {
		this.last_time_search = payload as IDateTimeSearch;
		this.date_time_picked = Object.values(this.last_time_search).every((value) => !!value);

		// Store in localStorage as well
		if (localStorage) {
			localStorage.setItem('date-time-searched', JSON.stringify(payload));
		}
	}

	@VuexMutation
	storeSingleSlot(payload: any) {
		this.slot_type = payload
	}

	@VuexMutation
	resetDateTimePicked() {
		if (localStorage) {
			localStorage.removeItem('date-time-searched');
		}
		this.last_time_search = new IDateTimeSearch();
		this.date_time_picked = false;
	}

	@VuexMutation
	clearBookingStored() {
		this.booking = new Booking();
		this.last_time_search = new IDateTimeSearch();
		this.date_time_picked = false;
	}

	@VuexMutation
	setIsSingleDay(payload: boolean) {
	  this.is_single_day = payload;
	}

	// ------------------------------------------------
	// ------------- Actions --------------------------
	// ------------------------------------------------
	// 'context' is passed by default in actions as 'this.context'

	@VuexAction({ rawError: true })
	CLEAR_BOOKING_STORED() {
		this.context.commit('clearBookingStored');
		if (!localStorage) {
			return;
		}
		localStorage.removeItem('date-time-searched');
		localStorage.removeItem('booking');
	}

	@VuexAction({ rawError: true })
	async CHECK_BOOKING(payload?: Booking): Promise<boolean> {
		(this as any).store.$logger.console({ message: 'CHECK_BOOKING' });

		let booking;

		if (payload) {
			booking = payload;
		} else if (localStorage) {
			booking = new Booking(JSON.parse(localStorage.getItem('booking') as string));
		}

		if (booking) {
			if (!booking.time_start) {
				// no time_start in booking - clear and go back home
				await this.CLEAR_BOOKING_STORED();
				await BOOKING.CLEAR_BOOKING_STORED();
				return false;
			}

			let booking_to_store = new Booking();

			// Check if booking is created - if so get booking from server
			// this also runs after the booking is fetched from the checkout beforeEnter guard - could skip it checking all checkout routes..
			// router.currentRoute.name != routesNames.checkout OR params.id == booking.id
			if (!payload && booking.id && router.currentRoute.params.id !== booking.id) {
				(this as any).store.$logger.console({ message: 'Getting booking from server' });
				const res = await BOOKING.GET_BOOKING({ id: booking.id });

				if (res instanceof ErrorResponse) {
					(this as any).store.$logger.console({ message: 'Booking not found / expired -- Clearing' });
					this.CLEAR_BOOKING_STORED();
				} else {
					booking_to_store = res;
				}
			} else {
				booking_to_store = new Booking(booking);
			}

			// if user is logged in - assign his ID if empty otherwise check if they match
			if (!payload && USER.is_logged_in) {
				if (!booking.customer) {
					booking_to_store.customer = USER.data.id;
				}
				if (USER.data.id !== booking.customer) {
					// Do we need that still? Should be checked in the guard beforehand
					(this as any).store.$logger.console({ message: "Booking and User IDs don't match - clearing booking" });
					this.CLEAR_BOOKING_STORED();
					return false;
				}
			}

			// If a time end is set and is previous to now - clear booking
			if (booking.time_end) {
				if (dayjs().valueOf() > dayjs(booking.time_end).valueOf()) {
					await this.CLEAR_BOOKING_STORED();
					BOOKING.CLEAR_BOOKING_STORED();
					return false;
				}
			}
			if (!payload) {
				// only on localstorage check
				this.context.commit('storeBookingValues', booking_to_store);
			}
			return true;
		}

		(this as any).store.$logger.console({ message: 'No booking in CHECK BOOKING' });
		this.CLEAR_BOOKING_STORED();
		return true; // to not be redirect home by default.vue
	}

	@VuexAction
	STORE_DATE_TIME_PICKED(payload: IDateTimeSearch) {
		if (!payload.start || !payload.end) {
			return;
		}
		// convert to austrian timezone
		const start = dateToAustrianTimezone(payload.start).format();
		const end = dateToAustrianTimezone(payload.end).format();

		this.context.commit('storeDateTimePicked', { start: start, end: end });

		this.booking.time_start = start;
		this.booking.time_end = end;
	}

	@VuexAction
	STORE_SINGLE_SLOT_TYPE(type: SlotType) {
		this.context.commit('storeSingleSlot', type);
	}

	@VuexAction
	CLEAR_SINGLE_SLOT() {
		this.context.commit('storeSingleSlot', SlotType.Default);
	}

	@VuexAction
	async STORE_BOOKING_DATA(payload: Booking): Promise<boolean> {
		let res;
		// fill client if logged in
		if (USER.data.id && !payload.customer) {
			payload.customer = USER.data.id;
		}

		if (payload.id) {
			(this as any).store.$logger.console({ message: 'Cart Module - updating booking' });
			res = await BOOKING.UPDATE_BOOKING({ booking_id: payload.id, booking: payload });

			if (res instanceof ErrorResponse) {
				return false;
			}
		}

		this.context.commit('storeBookingValues', res || payload);
		return true;
	}

	@VuexAction
	IS_SINGLE_DAY(payload: boolean) {
		this.context.commit('setIsSingleDay', payload);
	}
}

export default getModule(CART);
