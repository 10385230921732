import { getRoutePath, routesNames } from '@/router';

export default async function (context: any): Promise<void> {
	context.$logger.console({ message: 'fetchLPData middleware' });

	const domain = context.$getDomain();
	const domain_changed = context.store.state.MISC_DATA.current_domain !== domain;

	const path = context.route.path.replace('/', '');
	const current_LP = context.store.state.MISC_DATA.current_LP_data;
	const landing_pages = context.store.state.MISC_DATA.landing_pages;

	let id;

	for (const i in landing_pages) {
		if (landing_pages[i].url.replace('/', '') === path) {
			id = landing_pages[i].id;
			break;
		}
	}

	if (!id) {
		context.redirect({ path: getRoutePath(routesNames.all_trucks, domain) });
		return;
	}

	// request the current LP data and store
	if (!current_LP || (current_LP && current_LP.id !== id) || domain_changed) {
		context.$logger.console({ message: 'Requesting & storing LP Data from middleware' });

		const res = await context.store.dispatch('GET_LANDING_PAGE', { domain: domain, id: id });
		if (!res) {
			context.redirect({ path: getRoutePath(routesNames.all_trucks, domain) });
		}
	}
}
