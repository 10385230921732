import { Plugin } from '@nuxt/types';

declare module 'vue/types/vue' {
	interface Vue {
		$logger: {
			console: (params: { message?: string; data?: any; sentry_log?: boolean }) => void;
			sentry: (params: { message?: string; error?: Error }) => void;
		};
	}
}

declare module '@nuxt/types' {
	// nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
	interface NuxtAppOptions {
		$logger: {
			console: (params: { message?: string; data?: any; sentry_log?: boolean }) => void;
			sentry: (params: { message?: string; error?: Error }) => void;
		};
	}
	// nuxtContext.$myInjectedFunction
	interface Context {
		$logger: {
			console: (params: { message?: string; data?: any; sentry_log?: boolean }) => void;
			sentry: (params: { message?: string; error?: Error }) => void;
		};
	}
}

const loggerPlugin: Plugin = ({ app }, inject): void => {
	if (!process.env) {
		throw new Error('process.env is undefined -- From Logger Plugin (plugins/logger.ts) --');
	}

	const on_production = process.env.NODE_ENV === 'production';

	inject('logger', {
		console: function (params: { message?: string; data?: any; sentry_log?: boolean }): void {
			const server_side = process.server;
			let show_anyway = null as boolean | null

			if (!server_side) {
				show_anyway = sessionStorage.getItem('LOG') !== null;
			}

			// Don't run if we don't force it AND on prod + it's not a sentry log
			if (on_production && !show_anyway && !params.sentry_log) {
				return;
			}

			const current_route_name = app.getRouteBaseName();
			const message = params.message ? params.message : 'No message passed';
			const text_color = params.sentry_log ? '#a970fa' : '#fad97d';
			const add_route_info = current_route_name ? `logged on route name: ${app.getRouteBaseName()}` : '';

			if (server_side) {
				console.log('%c↓↓ NUXT SERVER ↓↓', `color: ${'#fa6d57'}; font-family:monospace; font-size: 12px`);
			}
			// log message
			console.log(`%c${params.sentry_log ? 'Sentry log -> ' : ''}${message} || ${add_route_info}`, `color: ${text_color}; font-family:monospace; font-size: 12px`);
			// log data passed if defined
			if (params.data) {
				console.log(`%c ${params.sentry_log ? 'Sentry error -> ' : 'Logger data -> '}`, 'color: orange; font-family:monospace; font-size: 11px', params.data);
			}
		},

		sentry: function (params: { message?: string; error?: Error }): void {
			if (!on_production) {
				// abort on dev
				return;
			}

			if (!params.message && !params.error) {
				// In case not passing params
				return;
			}

			let log_msg;

			// Sentry message report
			if (params.message) {
				log_msg = `captureMessage : ${params.message}`;
				app.$sentry.captureMessage(params.message);
			}
			// Sentry error report
			if (params.error) {
				log_msg = 'captureException ';
				app.$sentry.captureException(params.error);
			}

			// log in console
			app.$logger.console({
				message: log_msg,
				data: params.error ?? undefined,
				sentry_log: true
			});
		}
	});
};

export default loggerPlugin;
