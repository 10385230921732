import Vue from 'vue';
import currentDomain from '@/mixins/currentDomain';

const getDomainPrices = Vue.extend({
  mixins: [currentDomain],
  methods: {
    getDomainInfo(): any {
      if (this.isDeDomain || this.isAtDomain) {
        return {
          priceRange: { min: 9, max: 44, step: 5 },
          currency: '€',
        };
      }

      if (this.isCzDomain) {
        return {
          priceRange: { min: 390, max: 1090, step: 100 },
          currency: 'CZK',
        };
      }

      if (this.isHuDomain) {
        return {
          priceRange: { min: 8990, max: 12990, step: 1000 },
          currency: 'HUF',
        };
      }

      return {
        priceRange: { min: 9, max: 44, step: 5 },
        currency: '€',
      };
    }
  },

  computed: {
    getDomainPrices(): any {
      if (this.isDeDomain || this.isAtDomain) {
        return {
          priceRange: { min: 9, max: 44, step: 5 },
          currency: '€',
        };
      }

      if (this.isCzDomain) {
        return {
          priceRange: { min: 390, max: 1090, step: 100 },
          currency: 'CZK',
        };
      }

      if (this.isHuDomain) {
        return {
          priceRange: { min: 8990, max: 12990, step: 1000 },
          currency: 'HUF',
        };
      }

      return {
        priceRange: { min: 9, max: 44, step: 5 },
        currency: '€',
      };
    }
  }
});

export default getDomainPrices;
