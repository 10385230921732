import { AModel } from '@/types/class_helper';

export enum EPositionName {
	TRUCK1 = 'truckl',
	TRUCK1_6 = 'truckl6hrs',
	TRUCK1_6OFF = 'truckl6hrsofftime',
	TRUCK1_6PEAK = 'truckl6hrspeaktime',
	TRUCK1_6PEAK2 = 'truckl6hrspeaktime2',
	TRUCK2 = 'truckxl',
	TRUCK2_6 = 'truckxl6hrs',
	TRUCK2_6OFF = 'truckxl6hrsofftime',
	TRUCK2_6PEAK = 'truckxl6hrspeaktime',
	TRUCK2_6PEAK2 = 'truckxl6hrspeaktime2',
	TRUCK3 = 'truckxxl',
	TRUCK3_6 = 'truckxxl6hrs',
	TRUCK3_6OFF = 'truckxxl6hrsofftime',
	TRUCK3_6PEAK = 'truckxxl6hrspeaktime',
	TRUCK3_6PEAK2 = 'truckxxl6hrspeaktime2',

	TRUCK = 'truckbase',
	
	PROT1 = 'protectionbasic',
	PROT1_6 = 'protectionbasic6hrs',
	PROT1_6PEAK = 'protectionbasic6hrspeaktime',
	PROT1_6PEAK2 = 'protectionbasic6hrspeaktime2',
	PROT1_6OFF = 'protectionbasic6hrsofftime',
	PROT1_2 = 'protectionbasicstandard',
	PROT1_3 = 'protectionbasiczero',
	PROT2 = 'protectionstandard',
	PROT2_6 = 'protectionstandard6hrs',
	PROT2_6PEAK = 'protectionstandard6hrspeaktime',
	PROT2_6PEAK2 = 'protectionstandard6hrspeaktime2',
	PROT2_6OFF = 'protectionstandard6hrsofftime',
	PROT2_3 = 'protectionstandardzero',
    PROT3 = 'protectionzero',
    PROT3_6 = 'protectionzero6hrs',
    PROT3_6PEAK = 'protectionzero6hrspeaktime',
    PROT3_6PEAK2 = 'protectionzero6hrspeaktime2',
    PROT3_6OFF = 'protectionzero6hrsofftime',
    PROT4 = 'protectionsmarteasy',
    PROT4_6 = 'protectionsmarteasy6hrs',
    PROT4_6PEAK = 'protectionsmarteasy6hrspeaktime',
    PROT4_6PEAK2 = 'protectionsmarteasy6hrspeaktime2',
    PROT4_6OFF = 'protectionsmarteasy6hrsofftime',

	UP_INT1 = 'international',
	UP_INT1_2 = 'upinternational1_2',
	UP_INT1_3 = 'upinternational1_3',
	UP_INT2 = 'international2',
	UP_INT2_3 = 'upinternational2_3',
	UP_INT3 = 'international3',
	UP_DRIVER = 'driver_perday',
	UP_CANCEL = 'upcancel',
	DEPOSIT = 'deposit',
	DEPOSIT_WAIVER = 'waiver',
	ABO = 'abo',
	BILLING_FEE = 'billingfee',
	VOUCHER = 'voucher',

	SERVICE_FEE = 'servicefee'
}

type Position = {
	[index: string]: string | number | null;

	pos: number;
	type: string | EPositionName;
	qty: number;
	tax: number;
	amount_gross: number;
	amount_net: number;
	amount_tax: number;
	total_gross: number;
	total_net: number;
	total_tax: number;
	info: string | null;
	currency: string | null;
}

export class Billing extends AModel {
	currency: string;
	positions: Position[];
	total_gross: number;
	total_net: number;
	total_tax: number;

	constructor(opt?: Partial<Billing>) {
		super();

		// Default values (all props)
		this.currency = '';
		this.positions = [];
		this.total_gross = 0;
		this.total_net = 0;
		this.total_tax = 0;

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Billing, Partial<Billing>>(this, sanitized);
		}
	}

	copy(): Billing {
		const copy = new Billing(this.toObject());
		return copy;
	}
}

export default Billing;
