/* eslint-disable prefer-regex-literals */
import Vue from 'vue';

const browserCheck = Vue.extend({
	computed: {
		isSafari() {
			return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		},
	},
});

export default browserCheck;