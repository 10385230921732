import TruckAvailable from '@/components/general/icons/TruckAvailable.vue';
import TruckAvailablePink from '@/components/general/icons/TruckAvailablePink.vue';
import TruckUnavailable from '@/components/general/icons/TruckUnavailable.vue';

import TruckBackLeftCorner from '@/components/general/icons/TruckBackLeftCorner.vue';
import TruckBackRightCorner from '@/components/general/icons/TruckBackRightCorner.vue';
import TruckFrontLeftCorner from '@/components/general/icons/TruckFrontLeftCorner.vue';
import TruckFrontRightCorner from '@/components/general/icons/TruckFrontRightCorner.vue';
import TruckLoadingBay from '@/components/general/icons/TruckLoadingBay.vue';
import TruckDriverCabin from '@/components/general/icons/TruckDriverCabin.vue';
import QrCodeScan from '@/components/general/icons/QrCodeScan.vue';

export default {
  breakpoints: {
    thresholds: {
      xl: 9999,
    },
  },
  theme: {
    disable: true,
    options: { customProperties: true },
    themes: {
      light: {
        cyan123: '#5FBEC3',
        pink123: '#F03899',
        orange123: '#F9653B',
        anchor: '#555555',
      },
    },
  },
  icons: {
    values: {
      truck_available: {
        component: TruckAvailable,
      },
      truck_available_pink: {
        component: TruckAvailablePink,
      },
      truck_unavailable: {
        component: TruckUnavailable,
      },
      back_left: {
        component: TruckBackLeftCorner,
      },
      back_right: {
        component: TruckBackRightCorner,
      },
      front_left: {
        component: TruckFrontLeftCorner,
      },
      front_right: {
        component: TruckFrontRightCorner,
      },
      loading_bay: {
        component: TruckLoadingBay,
      },
      driver_cab: {
        component: TruckDriverCabin,
      },
      qr_code: {
        component: QrCodeScan,
      },
    },
  },
};
