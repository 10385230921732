import { routesNames } from '@/router';

export default async function (context: any): Promise<void> {
	// get current TLD and request server for press list data
	context.$logger.console({ message: `Current domain (fetchLandingPages) : ${context.$getDomain()}` });
	const domain = context.$getDomain();
	const route_name = context.route.name;

	if (route_name === routesNames.landing_page || route_name === routesNames.all_trucks) {
		// For now we request no matter what to avoid this "Vuex caching fuckery on production"
		await context.store.dispatch('GET_LANDING_PAGE_LIST', domain);
	}
}
