
import Vue from 'vue';
import MISC_DATA from '@/store/modules/MiscDataModule';
import UTILS from '@/store/modules/UtilityModule';

import { getRoutePath, routesNames } from '@/router';

import currentDomain from '@/mixins/currentDomain';
import cookieConsent from '@/mixins/cookieConsent';

export default Vue.extend({
  name: 'FooterBooking',
  mixins: [currentDomain, cookieConsent],

  computed: {
    MISC_DATA: () => MISC_DATA,
    UTILS: () => UTILS,

    companyName(): string {
      return this.MISC_DATA.basedata.bu_info.name
        ? this.MISC_DATA.basedata.bu_info.name
        : (this.$t('general.company_name') as string);
    },
    footer() {
      const domain = this.$getDomain();
      return [
        {
          heading: this.$t('general.legal'),
          links: [
            {
              title: this.$t('general.imprint'),
              route: { path: getRoutePath(routesNames.imprint, domain) },
            },
            {
              title: this.$t('general.data_protection'),
              route: { path: getRoutePath(routesNames.privacy_policy, domain) },
            },
            {
              title: this.$t('general.conditions_short'),
              route: { path: getRoutePath(routesNames.agb, domain) },
            },
          ],
        },
      ];
    },
  },
});
